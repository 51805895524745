<template>
  <div>
    <!-- 头部 -->
    <Header :dlogBr="false"></Header>

    <!-- 内容 -->
    <div class="neir">
      <!--  -->
      <div class="neir-box">
        <!--  -->
        <div class="title">
          <div class="title-left">
            <div class="title-left-icon">
              <SvgIcon iconClass="succeed"></SvgIcon>
            </div>
            <p>Order Completed</p>
          </div>
          <div class="title-right">
            <SvgIcon iconClass="logo" style="font-size: 193px"></SvgIcon>
          </div>
        </div>
        <!-- 订单信息 -->
        <div class="order-info">
          <div class="item">
            <p>Order No.</p>
            <span>{{ orderInfo.order_sn }}</span>
          </div>
          <div class="item">
            <p>Payment</p>
            <span>{{ orderInfo.pay_type }}</span>
          </div>
          <div class="item">
            <p>Payment Date</p>
            <span v-if="orderInfo.pay_at"
              >{{
                translateMonth(orderInfo.pay_at.split(" ")[0].split("-")[1])
              }}
              {{ orderInfo.pay_at.split(" ")[0].split("-")[2] }},
              {{ orderInfo.pay_at.split(" ")[0].split("-")[0] }}
              {{ orderInfo.pay_at.split(" ")[1] }}</span
            >
          </div>
        </div>
        <!-- 用户信息 -->
        <div class="order-info">
          <div class="item">
            <p>Contact</p>
            <span>{{ orderInfo.username }}</span>
          </div>
          <div class="item">
            <p>Email</p>
            <span>{{ orderInfo.email }}</span>
          </div>
          <div class="item">
            <p></p>
            <span></span>
          </div>
        </div>
        <!-- 订单数量与价格 -->
        <div
          class="order-num"
          v-if="
            orderInfo &&
            orderInfo.ticket_data &&
            orderInfo.ticket_data.length != 0
          "
        >
          <div class="order-num-tit">
            <p>Moominvalley Park 1-Day Pass</p>
            <span v-if="orderInfo.use_at"
              >{{
                translateMonth(orderInfo.use_at.split(" ")[0].split("-")[1])
              }}
              {{ orderInfo.use_at.split(" ")[0].split("-")[2] }},
              {{ orderInfo.use_at.split(" ")[0].split("-")[0] }}</span
            >
          </div>
          <div
            v-for="(item, index) in orderInfo.ticket_data"
            :key="index"
            class="order-num-item"
          >
            <div class="order-num-item-l">
              <p v-if="item.tickets_type == 1">Adult</p>
              <p v-else-if="item.tickets_type == 2">Child</p>
              <p v-else-if="item.tickets_type == 4">Secondary Student</p>
              <span>x {{ item.num }}</span>
            </div>
            <div class="order-num-item-r">
              ￥{{ setSumPrice(item.total_price, 1) }}
            </div>
          </div>
          <!-- <div class="order-num-item">
            <div class="order-num-item-l">
              <p>Child</p>
              <span>x 1</span>
            </div>
            <div class="order-num-item-r">￥1,800</div>
          </div> -->
        </div>
        <div
          class="order-num"
          v-if="
            orderInfo &&
            orderInfo.night_data &&
            orderInfo.night_data.length != 0
          "
        >
          <div class="order-num-tit">
            <p>Moominvalley Park Night Pass</p>
            <span v-if="orderInfo.use_at"
              >{{
                translateMonth(orderInfo.use_at.split(" ")[0].split("-")[1])
              }}
              {{ orderInfo.use_at.split(" ")[0].split("-")[2] }},
              {{ orderInfo.use_at.split(" ")[0].split("-")[0] }}</span
            >
          </div>
          <div
            v-for="(item, index) in orderInfo.night_data"
            :key="index"
            class="order-num-item"
          >
            <div class="order-num-item-l">
              <p v-if="item.tickets_type == 1">Adult</p>
              <p v-else-if="item.tickets_type == 2">Child</p>
              <p v-else-if="item.tickets_type == 4">Secondary Student</p>
              <span>x {{ item.num }}</span>
            </div>
            <div class="order-num-item-r">
              ￥{{ setSumPrice(item.total_price, 1) }}
            </div>
          </div>
          <!-- <div class="order-num-item">
            <div class="order-num-item-l">
              <p>Child</p>
              <span>x 1</span>
            </div>
            <div class="order-num-item-r">￥1,800</div>
          </div> -->
        </div>
        <!-- 合计 -->
        <div class="total">
          <p>Total</p>
          <span>￥{{ setSumPrice(totalPrices, 1) || "-" }}</span>
        </div>
      </div>
      <!-- 下载 票 -->
      <div
        v-if="orderInfo.order_codes && orderInfo.order_codes.length"
        class="download-code"
      >
        <div class="download-code-tit">
          <div class="icon-box"><i class="el-icon-bottom"></i></div>
          Download Ticket
        </div>

        <!--  -->
        <div
          v-for="(item, index) in orderInfo.order_codes"
          :key="index"
          class="item"
        >
          <div class="item-left">
            No.{{ item.code.replace(/^(.).*(.)$/, "$1******$2") }}
          </div>
          <div
            :class="[
              'item-right',
              { xiazai: item.status == '1' },
              { chuli: item.status != '1' },
            ]"
          >
            <el-button @click="infiniteRightBtn(item.url)"
              >{{ item.status == "1" ? "Download" : "Processing" }}
            </el-button>
          </div>
        </div>
        <!-- <div class="item">
          <div class="item-left">No.12******90</div>
          <div class="item-right chuli">
            <el-button v-if="'Processing'">Processing</el-button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import SvgIcon from "@/components/SvgIcon.vue";

import { get_orderInfo } from "@/api/common.js";
export default {
  data() {
    return {
      id: "",
      orderInfo: {},
      totalPrices: "", // 总价
    };
  },
  methods: {
    // 电子凭证下载
    infiniteRightBtn(url) {
      window.open(url, "_blank");
    },
    // 获取内容
    async getOrderInfoFn(id) {
      let res = await get_orderInfo(id);
      this.orderInfo = res.data.data;
      console.log(this.orderInfo);

      // 计算总价
      // console.log(1111);
      // if (this.orderInfo.tickets.length >= 3) {
      //   this.totalPrices =
      //     +this.orderInfo.tickets[0].total_price +
      //     +this.orderInfo.tickets[1].total_price +
      //     +this.orderInfo.tickets[2].total_price;
      // } else if (this.orderInfo.tickets.length >= 2) {
      //   this.totalPrices =
      //     +this.orderInfo.tickets[0].total_price +
      //     +this.orderInfo.tickets[1].total_price;
      // } else {
      //   this.totalPrices = +this.orderInfo.tickets[0].total_price;
      // }
      let onedaytotalPrice = 0;
      let nighttotalPrice = 0;

      if (this.orderInfo.ticket_data.length > 0) {
        for (let i = 0; i < this.orderInfo.ticket_data.length; i++) {
          onedaytotalPrice += +this.orderInfo.ticket_data[i].total_price;
        }
      }

      // 夜间票
      if (this.orderInfo.night_data.length > 0) {
        for (let i = 0; i < this.orderInfo.night_data.length; i++) {
          nighttotalPrice += +this.orderInfo.night_data[i].total_price;
        }
      }

      this.totalPrices = onedaytotalPrice + nighttotalPrice;
    },
  },
  mounted() {
    if (this._isMobile()) {
      // 跳转至手机端路由
      if (this.$route.path != `/m_order_preview`) {
        this.$router.replace(`/m_order_preview?x=${this.id}`);
      }
    } else {
      // 跳转至 pc 端路由
      if (this.$route.path != `/order_preview`) {
        this.$router.replace(`/order_preview?x=${this.id}`);
      }
    }
  },
  created() {
    console.log("wsm");
    this.id = this.$route.query.x;
    console.log(this.id);

    this.getOrderInfoFn(this.id);

    localStorage.removeItem("orderParam");
    localStorage.removeItem("pasInfoForm");
  },
  components: {
    Header,
    SvgIcon,
  },
};
</script>

<style lang="less" scoped>
.neir {
  width: 1030px;
  margin: 20px auto 30px;
  padding: 0 15px;

  .neir-box {
    background-color: #fff;
    border-radius: 12px;
    padding: 40px;
    padding-bottom: 0;
    .title {
      display: flex;
      justify-content: space-between;
      height: 90px;
      .svg-icon {
        height: 100%;
      }
      .title-left {
        display: flex;
        font-weight: bold;
        font-size: 24px;
        color: #12334c;
        .title-left-icon {
          width: 22px;
          height: 22px;
          background-color: #12334c;
          color: #fff;
          border-radius: 50%;
          text-align: center;
          line-height: 22px;
          font-size: 12px;
          margin-top: 4px;
          margin-right: 10px;
        }
      }
    }

    //
    .order-info {
      display: flex;
      justify-content: space-between;
      padding: 40px 0;
      border-bottom: 1px solid #b2bac13d;
      color: #12334c;
      .item {
        width: 320px;
        p {
          margin-bottom: 12px;
          font-size: 16px;
        }
        span {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    //
    .order-num {
      padding: 40px 0;
      border-bottom: 1px solid #b2bac13d;
      color: #12334c;
      font-weight: bold;
      font-size: 18px;
      .order-num-tit {
        margin-bottom: 30px;
        p {
          margin-bottom: 10px;
        }
      }

      .order-num-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
        .order-num-item-l {
          display: flex;
          p {
            width: 320px;
          }
        }
      }
    }
    //
    .total {
      height: 119px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
      color: #12334c;
    }
  }

  //
  .download-code {
    margin-top: 20px;
    background-color: #fff;
    padding: 40px;
    padding-bottom: 0;
    border-radius: 12px;

    .download-code-tit {
      display: flex;
      font-size: 24px;
      font-weight: bold;
      align-items: center;
      color: #12334c;
      .icon-box {
        width: 22px;
        height: 22px;
        line-height: 22px;
        border-radius: 50%;
        background-color: #12334c;
        text-align: center;
        margin-right: 10px;
        font-size: 16px;
      }
      /deep/.el-icon-bottom:before {
        content: "\e6eb";
        color: #fff;
        font-weight: bold;
      }
    }

    .item {
      padding: 40px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #b2bac13b;
      &:last-child {
        border-bottom: 0;
      }

      .item-left {
        font-size: 18px;
        font-weight: bold;
        color: #12334c;
      }

      .item-right {
        .el-button {
          width: 119px;
          height: 42px;
          border-radius: 7px;
          background: #12334c;
          color: #fff;
          font-size: 16px;
        }

        &.chuli {
          .el-button {
            background-color: #b2bac1;
          }
        }
      }
    }
  }
}
</style>